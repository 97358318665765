<template>
  <v-data-table
    :headers="headers"
    :items="itens"
    :items-per-page="10"
    :page="pageNumber"
    class="elevation-1 tabela-artigos"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Artigos por página:',
      'items-per-page-all-text': 'Todos',
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.aceiteTermosUso`]="{ item }">
      {{ item.aceiteTermosUso ? "Sim" : "Não" }}
    </template>

    <template v-slot:[`item.produtos`]="{ item }">
      <div v-for="produto in item.produtos" :key="produto.id">
        {{ produto.nome }}
      </div>
    </template>

    <template v-slot:[`item.tags`]="{ item }">
      <div v-for="tag in item.tags" :key="tag.id">
        <v-chip class="ma-1" small>
          {{ tag.nome }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.categorias`]="{ item }">
      <div v-for="categoria in item.categorias" :key="categoria.id">
        <v-chip class="ma-1" small>
          {{ categoria.nome }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-btn small depressed color="fmq_gray" dark @click="$emit('selecionar', item)">
        Selecionar
      </v-btn>
    </template>

    <template v-slot:[`item.link`]="{ item }">
      <v-btn
        small
        depressed
        color="fmq_gray"
        outlined
        dark
        @click="openPDF(item.hash)"
      >
        PDF
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ArtigosTabelaBuscar",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageText: {
      type: String,
      default: "-",
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? "DESC" : "ASC"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    openPDF(hash) {
      window.open(
        process.env.VUE_APP_API + "donwload/artigos/" + hash,
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss">
.tabela-artigos tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-artigos tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
